<template>
    <div class="report">
        <div v-if="isLoggedIn" class="download">
            <div class="button" @click="onDownloadForm">匯出表單</div>
            <div class="button" @click="onDownloadUser">匯出登入資訊</div>
        </div>
        <div v-else class="login">
            <Input v-model="password" type="password"></Input>
            <div
                class="button"
                :class="{ disabled: !password }"
                @click="onValidate"
            >
                登入
            </div>
        </div>
    </div>
</template>

<script>
import XLSX from "xlsx";
import _ from "lodash";
import moment from "moment";

import { DatePicker, Input } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import { downloadForm, downloadUser } from "@/db";

const PWD = "WENKxHealth_Taichung_Gov";

export default {
    components: { DatePicker, Input },
    data() {
        return {
            isLoggedIn: false,
            password: "",

            dateInterval: [],
        };
    },
    methods: {
        async onDownloadForm() {
            const DATA = await downloadForm();
            const WB = XLSX.utils.book_new();
            const WS = XLSX.utils.json_to_sheet(DATA, {
                header: [
                    "性別",
                    "年齡",
                    "職業",
                    "從哪裡得知活動訊息",
                    "為什麼想參加闖關活動",
                    "你最喜歡哪個關卡",
                    "參與闖關活動後，我更了解心理健康知識",
                    "未來我會想再了解更多心理健康相關的內容",
                    "心情不好，想要找人談談，可以撥打下列哪支電話？",
                    "下列何者是正確的精神疾病用語？",
                    "下列何者是臺中市社區心理衛生中心業務內容？",
                    "如果發現親朋好友心情不好，可以怎麼幫他？",
                    "參加者姓名",
                    "參加者聯絡電話",
                    "參加者Email",
                    "獎品收件地址",
                    "歡迎分享參加闖關活動的心得，或是活動可以改善的地方",
                ],
            });
            XLSX.utils.book_append_sheet(WB, WS);

            const TODAY = moment(new Date()).format("YYYY-MM-DD");

            const FILE_NAME = `到${TODAY}為止表單`;
            XLSX.writeFile(WB, `${FILE_NAME}.xlsx`);
        },
        async onDownloadUser() {
            const DATA = await downloadUser();

            const WB = XLSX.utils.book_new();
            const SHEET_ROW = [];

            const LABEL = {
                userId: "UUID",
                displayName: "LINE名稱",
                pictureUrl: "大頭貼連結",
                statusMessage: "狀態文字",
            };

            DATA.forEach((data) => {
                const TEMP = {};
                _.forEach(LABEL, (newKey, key) => {
                    const VALUE = data[key];
                    TEMP[newKey] = VALUE;
                });
                SHEET_ROW.push(TEMP);
            });

            const WS = XLSX.utils.json_to_sheet(SHEET_ROW, {
                header: ["LINE名稱", "UUID", "狀態文字", "大頭貼連結"],
            });
            XLSX.utils.book_append_sheet(WB, WS);

            const TODAY = moment(new Date()).format("YYYY-MM-DD");

            const FILE_NAME = `到${TODAY}為止登入資訊`;
            XLSX.writeFile(WB, `${FILE_NAME}.xlsx`);
        },
        onValidate() {
            if (this.password === PWD) this.isLoggedIn = true;
            else this.password = "";
        },
    },
};
</script>

<style lang="scss" scoped>
.report {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.login {
    width: 300px;
}
.space {
    border: 1px solid;
    margin: 50px 0;
}
.download {
    width: 350px;
}
.button {
    cursor: pointer;
    margin-top: 50px;
    font-size: 20px;
    padding: 10px;
    text-align: center;
    background-color: #a17a76;
    color: #ffffff;
    font-weight: bold;
    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
        background-color: #999;
        color: #666;
    }
}
</style>
